export default {
  path: '/system',
  component: () => import('../views/platform/index.vue'),
  children: [
    // 渠道来源管理
    {
      path: 'channelsourcelist',
      name: 'channelsourcelist',
      meta: {
        permissionCode: 'channelsourcemanage',
        routerType: 1,
      },
      component: () => import('../views/platform/channel-source-manage/index'),

    },
    // 订阅消息管理
    {
      path: 'subscribemanage',
      name: 'subscribemanage',
      meta: {
        permissionCode: 'subscribemanage',
        routerType: 1,
      },
      component: () => import('../views/platform/subscribe-manage/subscribemanage.vue'),

    },
    // 小程序参数配置
    {
      path: 'miniprogramconfig',
      name: 'miniprogramconfig',
      meta: {
        permissionCode: 'miniprogramconfig',
        routerType: 1,
      },
      component: () => import('../views/platform/mini-program-config/mini-program-config.vue'),


    },
    // 小程序管理
    {
      path: 'authorization',
      name: 'authorization',
      meta: {
        permissionCode: 'miniprogrammanage',
        routerType: 1,
      }, component: () => import('../views/platform/miniprogram-manage/authorization'),
    },
    // 小程序管理
    {
      path: 'pagePath',
      name: 'pagePath',
      meta: {
        permissionCode: 'stayPagePath',
        routerType: 1,
      }, component: () => import('../views/platform/pagePath/pagePath'),
    },
    {
      path: 'experiencermanage',
      name: 'experiencermanage',
      meta: {
        permissionCode: 'miniprogrammanage',
        routerType: 1,
      },
      component: () => import('../views/platform/miniprogram-manage/experiencer-manage'),
    },
    {
      path: 'draftmanage',
      name: 'draftmanage',
      meta: {
        permissionCode: 'miniprogrammanage',
        routerType: 1,
      },
      component: () => import('../views/platform/miniprogram-manage/draft-manage'),
    },
    {
      path: 'templatemanage',
      name: 'templatemanage',
      meta: {
        permissionCode: 'miniprogrammanage',
        routerType: 1,
      },
      component: () => import('../views/platform/miniprogram-manage/template-manage'),
    },
    {
      path: 'uploadtemplatemanage',
      name: 'uploadtemplatemanage',
      meta: {
        permissionCode: 'miniprogrammanage',
        routerType: 1,
      },
      component: () => import('../views/platform/miniprogram-manage/uploadtemplate-manage'),
    },
    {
      path: 'examinelist',
      name: 'examinelist',
      meta: {
        permissionCode: 'miniprogrammanage',
        routerType: 1,
      },
      component: () => import('../views/platform/miniprogram-manage/examine-list'),
    },
    {
      path: 'examinedetail',
      name: 'examinedetail',
      meta: {
        permissionCode: 'miniprogrammanage',
        routerType: 1,
      },
      component: () => import('../views/platform/miniprogram-manage/examine-detail'),
    },
    {
      path: 'experiencerlist',
      name: 'experiencerlist',
      meta: {
        permissionCode: 'miniprogrammanage',
        routerType: 1,
      },
      component: () => import('../views/platform/miniprogram-manage/experiencer-list'),
    },

    // PMS路由管理
    {
      path: 'pmsurlmanage/index',
      name: 'classifymanage',
      meta: {
        permissionCode: 'pmsurlemanage',
        routerType: 1,
      },
      component: () => import('../views/platform/pms-url-manage/add-classify.vue'),
    },
    {
      path: 'pmsurlmanage/classifydeploy',
      name: 'classifydeploy',
      meta: {
        permissionCode: 'pmsurlemanage',
        routerType: 1,
      },
      component: () => import('../views/platform/pms-url-manage/deploy-classify.vue'),
    },
    {
      path: 'pmsurlmanage/deployIp',
      name: 'classifydeployIp',
      meta: {
        permissionCode: 'pmsurlemanage',
        routerType: 1,
      },
      component: () => import('../views/platform/pms-url-manage/deploy-ip.vue'),
    },
    {
      path: 'pmsurlmanage/relatehotel',
      name: 'classifyrelatehotel',
      meta: {
        permissionCode: 'pmsurlemanage',
        routerType: 1,
      },
      component: () => import('../views/platform/pms-url-manage/classify-relate-hotel.vue'),
    },

    // 系统对接平台

    {
      path: 'systemdocking/product-list',
      name: 'product-list',
      meta: {
        permissionCode: 'systemdocking',
        routerType: 1,
      },
      component: () => import('../views/platform/systemdocking/product-list.vue'),
    },

    {
      path: 'systemdocking/business-service-list',
      name: 'business-service-list',
      meta: {
        permissionCode: 'systemdocking',
        routerType: 1,
      },
      component: () => import('../views/platform/systemdocking/business-service-list.vue'),
    },

    {
      path: 'systemdocking/business-interface-list',
      name: 'business-interface-list',
      meta: {
        permissionCode: 'systemdocking',
        routerType: 1,
      },
      component: () => import('../views/platform/systemdocking/business-interface-list.vue'),
    },

    {
      path: 'systemdocking/cluster-dictionary-list',
      name: 'cluster-dictionary-list',
      meta: {
        permissionCode: 'systemdocking',
        routerType: 1,
      },
      component: () => import('../views/platform/systemdocking/cluster-dictionary-list.vue'),
    },

    {
      path: 'systemdocking/product-service-cluster-list',
      name: 'product-service-cluster-list',
      meta: {
        permissionCode: 'systemdocking',
        routerType: 1,
      },
      component: () => import('../views/platform/systemdocking/product-service-cluster-list.vue'),
    },

    {
      path: 'systemdocking/business-service-deploy',
      name: 'business-service-deploy',
      meta: {
        permissionCode: 'systemdocking',
        routerType: 1,
      },
      component: () => import('../views/platform/systemdocking/business-service-deploy.vue'),

    },
    {
      path: 'systemdocking/im-clint-manage',
      name: 'imclintmanage',
      meta: {
        permissionCode: 'systemdocking',
        routerType: 1,
      },
      component: () => import('../views/platform/systemdocking/im-clint-manage.vue'),

    },
    // 租户管理平台

    {
      path: 'tenentmanageplatform/groupproductconfig',
      name: 'groupproductconfig',
      meta: {
        permissionCode: 'tenentmanageplatform',
        routerType: 1,
      },
      component: () => import('../views/platform/tenentmanageplatform/groupproductconfig.vue'),
    },
    {
      // 集团、酒店业务产品线集群列表
      path: 'tenentmanageplatform/unit-service-product-cluster-related',
      name: 'unit-service-product-cluster-related',
      meta: {
        permissionCode: 'tenentmanageplatform',
        routerType: 1,
      },
      component: () => import('../views/platform/tenentmanageplatform/unitserviceproductcluster.vue'),
    },
    {
      // 集团、酒店业务产品线集群列表
      path: 'tenentmanageplatform/unit-service-product-cluster-unrelated',
      name: 'unit-service-product-cluster-unrelated',
      meta: {
        permissionCode: 'tenentmanageplatform',
        routerType: 1,
      },
      component: () => import('../views/platform/tenentmanageplatform/unitserviceproductclusterunrelated.vue'),
    },
    {
      path: 'tenentmanageplatform/hotelproductconfig',
      name: 'hotelproductconfig',
      meta: {
        permissionCode: 'tenentmanageplatform',
        routerType: 1,
      },
      component: () => import('../views/platform/tenentmanageplatform/hotelproductconfig.vue'),

    },
    {
      path: 'tenentmanageplatform/group-hotel-clint-manage',
      name: 'grouphotelclintmanage',
      meta: {
        permissionCode: 'tenentmanageplatform',
        routerType: 1,
      },
      component: () => import('../views/platform/tenentmanageplatform/group-hotel-clint-manage.vue'),

    },
    {
      // 集团上线
      path: 'groupdeploy',
      name: 'groupdeploy',
      meta: {
        permissionCode: 'groupdeploy',
        routerType: 1,
      },
      component: () => import('../views/platform/groupdeploy/groupdeploy.vue'),
    },
    {
      // 流程管理
      path: 'processmanage',
      name: 'processmanage',
      meta: {
        permissionCode: 'groupdeploy',
        routerType: 1,
      },
      component: () => import('../views/platform/groupdeploy/processmanage.vue'),
    },
    {
      // 流程组管理
      path: 'processgroupmanage',
      name: 'processgroupmanage',
      meta: {
        permissionCode: 'groupdeploy',
        routerType: 1,
      },
      component: () => import('../views/platform/groupdeploy/processgroupmanage.vue'),
    },
    {
      path: 'processgroupmanage/tag',
      name: 'tag',
      meta: {
        permissionCode: 'groupdeploy',
        routerType: 1,
      },
      component: () => import('../views/platform/groupdeploy/tag.vue'),
    },
    {
      path: 'minProgramParamSet',
      name: 'minProgramParamSet',
      meta: {
        permissionCode: 'minProgramParamSet',
        routerType: 1,
      },
      component: () => import('../views/platform/mini-program-center/mini-program-set.vue'),
    },
    {
      path: 'questionSet',
      name: 'questionSet',
      meta: {
        permissionCode: 'questionSet',
        routerType: 1,
      },
      component: () => import('../views/platform/mini-program-center/question-set.vue'),
    },
    {
      path: 'groupRateSet',
      name: 'groupRateSet',
      meta: {
        permissionCode: 'groupRateSet',
        routerType: 1,
      },
      component: () => import('../views/platform/mini-program-center/group-rate-set.vue'),
    },
    {
      path: 'whiteListSet',
      name: 'whiteListSet',
      meta: {
        permissionCode: 'whiteListSet',
        routerType: 1,
      },
      component: () => import('../views/platform/mini-program-center/white-list-set.vue'),
    },
    {
      path: 'minProgramCodeSet',
      name: 'minProgramCodeSet',
      meta: {
        permissionCode: 'minProgramCodeSet',
        routerType: 1,
      },
      component: () => import('../views/platform/mini-program-center/mini-program-code-set.vue'),
    },
    {
      path: 'hotel-system-deploy',
      name: 'hotel-system-deploy',
      meta: {
        permissionCode: 'hotelSystemDeploy',
        routerType: 1,
      },
      component: () => import('../views/platform/hotel-manage/hotel-system-deploy.vue'),
    }
  ],
};
